import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {LocationContext} from "../routes";

const NotFoundPage = ({location}: {
  location: any
}) => (
  <LocationContext.Provider value={location}>
    <Layout>
      <SEO title="404: Not found"/>
      <h1>404 - NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist...</p>
    </Layout>
  </LocationContext.Provider>
)

export default NotFoundPage
